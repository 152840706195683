/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateBoardImagePinRequest } from '../models/CreateBoardImagePinRequest';
import type { CreateBoardImagePinResponse } from '../models/CreateBoardImagePinResponse';
import type { CreateBoardRequest } from '../models/CreateBoardRequest';
import type { CreateBoardResponse } from '../models/CreateBoardResponse';
import type { CreateDiffusionJobInfoRequest } from '../models/CreateDiffusionJobInfoRequest';
import type { CreateDiffusionJobInfoResponse } from '../models/CreateDiffusionJobInfoResponse';
import type { CreateEarlyAccessInviteRequest } from '../models/CreateEarlyAccessInviteRequest';
import type { CreateEarlyAccessInviteResponse } from '../models/CreateEarlyAccessInviteResponse';
import type { CreatePointCloudDiffusionJobRequest } from '../models/CreatePointCloudDiffusionJobRequest';
import type { CreatePointCloudDiffusionJobResponse } from '../models/CreatePointCloudDiffusionJobResponse';
import type { CreatePromptAssistRequest } from '../models/CreatePromptAssistRequest';
import type { CreatePromptAssistResponse } from '../models/CreatePromptAssistResponse';
import type { DeleteBoardImagePinRequest } from '../models/DeleteBoardImagePinRequest';
import type { DeleteBoardImagePinResponse } from '../models/DeleteBoardImagePinResponse';
import type { DeleteBoardRequest } from '../models/DeleteBoardRequest';
import type { DeleteBoardResponse } from '../models/DeleteBoardResponse';
import type { DeleteDiffusionJobInfoRequest } from '../models/DeleteDiffusionJobInfoRequest';
import type { DeleteDiffusionJobInfoResponse } from '../models/DeleteDiffusionJobInfoResponse';
import type { DeletePointCloudDiffusionJobRequest } from '../models/DeletePointCloudDiffusionJobRequest';
import type { DeletePointCloudDiffusionJobResponse } from '../models/DeletePointCloudDiffusionJobResponse';
import type { GenerateWorldRequest } from '../models/GenerateWorldRequest';
import type { GenerateWorldResponse } from '../models/GenerateWorldResponse';
import type { GetBetaInfoResponse } from '../models/GetBetaInfoResponse';
import type { GetBoardImagePinsResponse } from '../models/GetBoardImagePinsResponse';
import type { GetBoardImagesResponse } from '../models/GetBoardImagesResponse';
import type { GetBoardResponse } from '../models/GetBoardResponse';
import type { GetBoardsResponse } from '../models/GetBoardsResponse';
import type { GetDiffusionJobImageResponse } from '../models/GetDiffusionJobImageResponse';
import type { GetEarlyAccessInvitesResponse } from '../models/GetEarlyAccessInvitesResponse';
import type { GetGeneratedImagesResponse } from '../models/GetGeneratedImagesResponse';
import type { GetGeneratedPointCloudResponse } from '../models/GetGeneratedPointCloudResponse';
import type { GetGeneratedPointCloudsResponse } from '../models/GetGeneratedPointCloudsResponse';
import type { GetGlbFileResponse } from '../models/GetGlbFileResponse';
import type { GetPlanInformationResponse } from '../models/GetPlanInformationResponse';
import type { GetPointCloudDiffusionJobsResponse } from '../models/GetPointCloudDiffusionJobsResponse';
import type { GetVoxFileResponse } from '../models/GetVoxFileResponse';
import type { PatchBoardRequest } from '../models/PatchBoardRequest';
import type { PatchBoardResponse } from '../models/PatchBoardResponse';
import type { PatchGeneratedImageRequest } from '../models/PatchGeneratedImageRequest';
import type { PatchGeneratedImageResponse } from '../models/PatchGeneratedImageResponse';
import type { PatchGeneratedPointCloudRequest } from '../models/PatchGeneratedPointCloudRequest';
import type { PatchGeneratedPointCloudResponse } from '../models/PatchGeneratedPointCloudResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Beta Info
     * @returns GetBetaInfoResponse Successful Response
     * @throws ApiError
     */
    public getBetaInfoBetaInfoGet(): CancelablePromise<GetBetaInfoResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/beta-info',
        });
    }

    /**
     * Get Early Access Invites
     * Gets all early access invites for this user.
     * @returns GetEarlyAccessInvitesResponse Successful Response
     * @throws ApiError
     */
    public getEarlyAccessInvitesEarlyAccessInvitesGet(): CancelablePromise<GetEarlyAccessInvitesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/early-access-invites',
        });
    }

    /**
     * Create Early Access Invite
     * Creates an early access invite for this user.
     * @param requestBody
     * @returns CreateEarlyAccessInviteResponse Successful Response
     * @throws ApiError
     */
    public createEarlyAccessInviteEarlyAccessInvitePost(
        requestBody: CreateEarlyAccessInviteRequest,
    ): CancelablePromise<CreateEarlyAccessInviteResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/early-access-invite',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Diffusion Job
     * Creates a diffusion job info resource.
     * Deletes any previously existing job info resources and associated images.
     * @param requestBody
     * @returns CreateDiffusionJobInfoResponse Successful Response
     * @throws ApiError
     */
    public createDiffusionJobDiffusionJobInfoPost(
        requestBody: CreateDiffusionJobInfoRequest,
    ): CancelablePromise<CreateDiffusionJobInfoResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/diffusion-job-info',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Diffusion Job
     * Deletes a diffusion job info resource and all associated images.
     * @param requestBody
     * @returns DeleteDiffusionJobInfoResponse Successful Response
     * @throws ApiError
     */
    public deleteDiffusionJobDiffusionJobInfoDelete(
        requestBody: DeleteDiffusionJobInfoRequest,
    ): CancelablePromise<DeleteDiffusionJobInfoResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/diffusion-job-info',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Diffusion Job Image
     * Gets a diffusion job image for a given job ID, step,
     * and upscale factor in the case of a final image that could have been upscaled.
     * @param jobId
     * @param step
     * @returns GetDiffusionJobImageResponse Successful Response
     * @throws ApiError
     */
    public getDiffusionJobImageDiffusionJobImageGet(
        jobId: string,
        step: number,
    ): CancelablePromise<GetDiffusionJobImageResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/diffusion-job-image',
            query: {
                'job_id': jobId,
                'step': step,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Generated Images
     * Gets generated images for the given parameters.
     * @param userId
     * @param saved
     * @param skip
     * @param limit
     * @returns GetGeneratedImagesResponse Successful Response
     * @throws ApiError
     */
    public getGeneratedImagesGeneratedImagesGet(
        userId: string,
        saved: boolean = false,
        skip?: number,
        limit: number = 100,
    ): CancelablePromise<GetGeneratedImagesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/generated-images',
            query: {
                'user_id': userId,
                'saved': saved,
                'skip': skip,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Patch Generated Image
     * Updates a generated image for this user.
     * @param requestBody
     * @returns PatchGeneratedImageResponse Successful Response
     * @throws ApiError
     */
    public patchGeneratedImagePatchGeneratedImagePatch(
        requestBody: PatchGeneratedImageRequest,
    ): CancelablePromise<PatchGeneratedImageResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/patch-generated-image',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Point Cloud Diffusion Job
     * Creates a point cloud diffusion job.
     * @param requestBody
     * @returns CreatePointCloudDiffusionJobResponse Successful Response
     * @throws ApiError
     */
    public createPointCloudDiffusionJobPointCloudDiffusionJobPost(
        requestBody: CreatePointCloudDiffusionJobRequest,
    ): CancelablePromise<CreatePointCloudDiffusionJobResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/point-cloud-diffusion-job',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Point Cloud Diffusion Job
     * Deletes a point cloud diffusion job.
     * @param requestBody
     * @returns DeletePointCloudDiffusionJobResponse Successful Response
     * @throws ApiError
     */
    public deletePointCloudDiffusionJobPointCloudDiffusionJobDelete(
        requestBody: DeletePointCloudDiffusionJobRequest,
    ): CancelablePromise<DeletePointCloudDiffusionJobResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/point-cloud-diffusion-job',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Point Cloud Diffusion Jobs
     * Gets all point cloud diffusion jobs that are pending or running for this user.
     * @returns GetPointCloudDiffusionJobsResponse Successful Response
     * @throws ApiError
     */
    public getPointCloudDiffusionJobsPointCloudDiffusionJobsGet(): CancelablePromise<GetPointCloudDiffusionJobsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/point-cloud-diffusion-jobs',
        });
    }

    /**
     * Get Generated Point Clouds
     * Gets all generated point clouds for this user.
     * @param saved
     * @param skip
     * @param limit
     * @returns GetGeneratedPointCloudsResponse Successful Response
     * @throws ApiError
     */
    public getGeneratedPointCloudsGeneratedPointCloudsGet(
        saved: boolean = false,
        skip?: number,
        limit: number = 100,
    ): CancelablePromise<GetGeneratedPointCloudsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/generated-point-clouds',
            query: {
                'saved': saved,
                'skip': skip,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Generated Point Cloud
     * Gets a generated point cloud for this user.
     * @param generationId
     * @returns GetGeneratedPointCloudResponse Successful Response
     * @throws ApiError
     */
    public getGeneratedPointCloudGeneratedPointCloudGet(
        generationId: string,
    ): CancelablePromise<GetGeneratedPointCloudResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/generated-point-cloud',
            query: {
                'generation_id': generationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Patch Generated Point Cloud
     * Updates a generated point cloud for this user.
     * @param requestBody
     * @returns PatchGeneratedPointCloudResponse Successful Response
     * @throws ApiError
     */
    public patchGeneratedPointCloudPatchGeneratedPointCloudPatch(
        requestBody: PatchGeneratedPointCloudRequest,
    ): CancelablePromise<PatchGeneratedPointCloudResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/patch-generated-point-cloud',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Vox File
     * Gets a vox file for this user.
     * @param generationId
     * @param gridSize
     * @param palette
     * @returns GetVoxFileResponse Successful Response
     * @throws ApiError
     */
    public getVoxFileVoxFileGet(
        generationId: string,
        gridSize: number,
        palette: string,
    ): CancelablePromise<GetVoxFileResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/vox-file',
            query: {
                'generation_id': generationId,
                'grid_size': gridSize,
                'palette': palette,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Glb File
     * Gets a glb file for this user.
     * @param generationId
     * @param gridSize
     * @returns GetGlbFileResponse Successful Response
     * @throws ApiError
     */
    public getGlbFileGlbFileGet(
        generationId: string,
        gridSize: number,
    ): CancelablePromise<GetGlbFileResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/glb-file',
            query: {
                'generation_id': generationId,
                'grid_size': gridSize,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Generate World
     * Creates a text generation job for world generation.
     * Returns the results. No job is stored in-memory.
     * @param requestBody
     * @returns GenerateWorldResponse Successful Response
     * @throws ApiError
     */
    public generateWorldGenerateWorldPost(
        requestBody: GenerateWorldRequest,
    ): CancelablePromise<GenerateWorldResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/generate-world',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Prompt Assist
     * Creates and returns a prompt assist for the provided data.
     * @param requestBody
     * @returns CreatePromptAssistResponse Successful Response
     * @throws ApiError
     */
    public createPromptAssistCreatePromptAssistPost(
        requestBody: CreatePromptAssistRequest,
    ): CancelablePromise<CreatePromptAssistResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/create-prompt-assist',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Plan Information
     * Gets information about the current plan.
     * @returns GetPlanInformationResponse Successful Response
     * @throws ApiError
     */
    public getPlanInformationPlanInformationGet(): CancelablePromise<GetPlanInformationResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/plan-information',
        });
    }

    /**
     * Get Boards
     * Returns all boards this user owns.
     * @returns GetBoardsResponse Successful Response
     * @throws ApiError
     */
    public getBoardsBoardsGet(): CancelablePromise<GetBoardsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/boards',
        });
    }

    /**
     * Get Board
     * Returns a board for this user.
     * @param boardId
     * @returns GetBoardResponse Successful Response
     * @throws ApiError
     */
    public getBoardBoardGet(
        boardId: string,
    ): CancelablePromise<GetBoardResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/board',
            query: {
                'board_id': boardId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Board
     * Creates a board for this user.
     * @param requestBody
     * @returns CreateBoardResponse Successful Response
     * @throws ApiError
     */
    public createBoardBoardPost(
        requestBody: CreateBoardRequest,
    ): CancelablePromise<CreateBoardResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/board',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Board
     * Deletes a board for this user.
     * @param requestBody
     * @returns DeleteBoardResponse Successful Response
     * @throws ApiError
     */
    public deleteBoardBoardDelete(
        requestBody: DeleteBoardRequest,
    ): CancelablePromise<DeleteBoardResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/board',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Patch Board
     * Updates a board for this user.
     * @param requestBody
     * @returns PatchBoardResponse Successful Response
     * @throws ApiError
     */
    public patchBoardBoardPatch(
        requestBody: PatchBoardRequest,
    ): CancelablePromise<PatchBoardResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/board',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Board Image Pins
     * Returns all board image pins for this user and board, if specified.
     * @param boardId
     * @param skip
     * @param limit
     * @returns GetBoardImagePinsResponse Successful Response
     * @throws ApiError
     */
    public getBoardImagePinsBoardImagePinsGet(
        boardId?: string,
        skip?: number,
        limit: number = 100,
    ): CancelablePromise<GetBoardImagePinsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/board-image-pins',
            query: {
                'board_id': boardId,
                'skip': skip,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Board Image Pin
     * Creates a board image pin for this user.
     * @param requestBody
     * @returns CreateBoardImagePinResponse Successful Response
     * @throws ApiError
     */
    public createBoardImagePinBoardImagePinPost(
        requestBody: CreateBoardImagePinRequest,
    ): CancelablePromise<CreateBoardImagePinResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/board-image-pin',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Board Image Pin
     * Deletes a board image pin for this user.
     * @param requestBody
     * @returns DeleteBoardImagePinResponse Successful Response
     * @throws ApiError
     */
    public deleteBoardImagePinBoardImagePinDelete(
        requestBody: DeleteBoardImagePinRequest,
    ): CancelablePromise<DeleteBoardImagePinResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/board-image-pin',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Board Images
     * Returns all board images for this user and board.
     * @param boardId
     * @returns GetBoardImagesResponse Successful Response
     * @throws ApiError
     */
    public getBoardImagesBoardImagesGet(
        boardId: string,
    ): CancelablePromise<GetBoardImagesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/board-images',
            query: {
                'board_id': boardId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
